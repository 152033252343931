import BaseModel from 'Models/BaseModel';
import {
  CAT_BRANDS,
  CAT_COMPANIES,
  CAT_CONTACTS,
  CAT_FILES,
  CAT_PATENTS,
  CAT_PRODUCTS,
  CAT_TRADEMARKS,
} from 'App/utils/Collections';
import { ROLE_COMPANIES, ROLE_CONTACTS, ROLE_STAFF, ROLE_ADMIN } from '@/app/constants/Roles';
import Img from 'Models/Img';
import { DataTableHeader, TableHeaderActions, TableHeaderName } from 'Lib/TypeDefs';
import { mdiDelete, mdiOpenInNew } from '@mdi/js';
import { MODULE_COMPANIES } from 'Lib/enums/ApiModules';
import { i18n } from '@/app/plugins/i18n';
import Dataman from '@/app/Dataman';

/**
 * @typedef {object} Location
 * @property {number} id
 * @property {name} id
 * @property {string} iso3166Alpha2
 */

/**
 * @property {number} id
 * @property {string} name
 * @property {string}foundationYear
 * @property {string} registrationNumber
 * @property {Array<{object}>} dbas
 * @property {Location} location
 * @property {object} [parentCompany]
 */
export default class Company extends BaseModel {
  static MODULE = MODULE_COMPANIES;
  static category = MODULE_COMPANIES;
  static getDefaults = () => ({
    id: null,
    draft: false,
    parentId: null,
    name: '',
    products: [],
    manufacturedProducts: [],
    brands: [],
    patents: [],
    contacts: [],
    markets: [],
    files: [],
    mediaResources: [],
    otherNames: [],
    dbas: [],
    productsTypes: [],
    location: {},
    parentCompany: {},
    numberOfBrands: 0,
    numberOfProducts: 0,
    patentAmount: 0,
    numberOfManufacturedProducts: 0,
    investments: [],
    numberOfTradeProducts: 0,
    numberOfTradeBrands: 0,
    numberOfManufacturedBrands: 0,
  });
  static getHeaders = (context) => {
    return [
      new DataTableHeader({
        text: BaseModel.tr('ID'),
        value: 'id',
        width: '1%',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('Name'),
        prop: 'name',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('Location'),
        prop: 'location.name',
        sortable: true,
      }),
      new TableHeaderName({
        text: BaseModel.tr('Region'),
        prop: 'region.name',
        sortable: true,
      }),
      new TableHeaderActions({
        text: '',
        actions: ['openTab', 'remove'],
        width: '1%',
        cellClass: 'no-wrap',
        actionsData: {
          openTab: {
            icon: mdiOpenInNew,
            color: 'info',
            hint: BaseModel.tr('Open in new tab'),
            visible: context && [CAT_COMPANIES].includes(context?.cat),
            callback: (item) => {
              window.open(item.ProfileLink(), '_blank');
            },
          },
          remove: {
            icon: mdiDelete,
            color: 'error',
            hint: BaseModel.tr('Remove'),
            visible: context && [CAT_COMPANIES].includes(context?.cat),
            emit: 'reload',
            callback: (item, companyId, subsidiariesIds) => {
              return Dataman.DB.updateCompany(companyId, {
                subsidiaries: [...subsidiariesIds.filter((subsidiaryId) => subsidiaryId !== item.props.id)],
              });
            },
          },
        },
      }),
    ];
  };

  constructor(props) {
    const { category, ...rest } = props || {};
    super(rest);
    this.setProps({ ...rest, category: Company.category, cat: category });
  }

  get images() {
    return (
      this.props.images?.map((img) => {
        return new Img(img);
      }) || []
    );
  }
  get defaultImage() {
    if (!this.props.defaultImage) {
      return (this.images || []).find((image) => !!image.default);
    }
    return new Img(this.props.defaultImage);
  }
  get defImageSrc() {
    return this.defaultImage?.src;
  }
  get defImageThumbSrc() {
    return this.defaultImage?.thumb;
  }
  get image() {
    return this.defImageSrc;
  }
  get patentsAmount() {
    return this._proxy.patentAmount || this._proxy.patents.length;
  }
  get brandsAmount() {
    return this._proxy.numberOfBrands || 0;
  }
  get productsAmount() {
    return this._proxy.numberOfProducts || 0;
  }
  get manufacturedProductsAmount() {
    return this.props.manufacturedProductsAmount || this._proxy.manufacturedProducts.length;
  }
  get contactsAmount() {
    return this._proxy.contacts.length;
  }
  get draft() {
    return this.props.draft;
  }
  get title() {
    const { name, location, region, isHeadCompany } = this._proxy;
    let text = name;
    const loc = Company.makeLocation({ location, region });
    if (loc) {
      text += ` (${loc})`;
    }
    if (isHeadCompany) {
      text += ` (${i18n.t('Main')})`;
    }
    return text;
  }
  get parentCompanyName() {
    const { parentCompany } = this._proxy;
    if (!parentCompany) {
      return '';
    }
    let txt = parentCompany?.name || '';
    const loc = Company.makeLocation(parentCompany);
    if (loc) {
      txt += ` (${loc})`;
    }
    return txt;
  }
  get location_string() {
    return this._proxy.location.name;
  }
  ProfileLink = () => {
    return this.href;
  };
  get href() {
    return `/companies/${this.props.id}`;
  }
  get CAT_COMPANIES() {
    return Company.category;
  }
  get locationCode() {
    return this._proxy.location?.iso3166Alpha2 || '';
  }

  get investee() {
    return this.props.investments || [];
  }

  PreviewContent = () => {
    return {};
  };
  getRelatedConf = () => [
    {
      id: 'cooperationBrands',
      title: BaseModel.tr('Cooperation with Brands'),
      category: CAT_BRANDS,
      filter: { 'tradingCompanies.id': this.id },
      isTable: true,
    },
    {
      id: 'manufacturedBrands',
      title: BaseModel.tr('Manufactured Brands'),
      category: CAT_BRANDS,
      filter: { 'products.manufacturers.id': this.id },
      isTable: true,
    },
    {
      id: 'childCompanies',
      title: BaseModel.tr('Branches'),
      category: CAT_COMPANIES,
      filter: { 'parentCompany.id': this.id },
      isTable: true,
    },
    {
      id: 'investments',
      title: BaseModel.tr('Investments'),
      category: CAT_COMPANIES,
      filter: { investor: this.id },
      isTable: true,
    },
    {
      id: 'manufacturedProducts',
      title: BaseModel.tr('Products manufactured'),
      category: CAT_PRODUCTS,
      filter: { manufacturers: this.id, 'order[appeared]': 'desc' },
      isTable: true,
    },
    {
      id: 'staff',
      title: BaseModel.tr('Staff'),
      category: CAT_CONTACTS,
      rolesRestrictions: [ROLE_STAFF, ROLE_ADMIN, [ROLE_CONTACTS, ROLE_COMPANIES]],
      restrictedText: BaseModel.tr('Access restricted'),
      filter: { 'employmentPositions.company.id': this.id },
      isTable: true,
    },
    {
      id: 'trademarks',
      title: BaseModel.tr('Trademarks'),
      category: CAT_TRADEMARKS,
      filter: { 'company.id': this.id },
      isTable: true,
    },
    {
      id: 'files',
      title: BaseModel.tr('Files'),
      category: CAT_FILES,
      filter: { companies: this.id },
      isTable: true,
    },
    {
      id: 'patents',
      title: BaseModel.tr('Patents'),
      category: CAT_PATENTS,
      filter: { 'companies.id': this.id },
      isTable: true,
      restrictions: [CAT_PATENTS],
      restrictedText: BaseModel.tr('Request access'),
    },
  ];

  /**
   * @param {object} data
   * @param {{ id: string|number, iso3166Alpha2: string, name: string }} data.location
   * @param {{ id: string|number, iso3166Alpha2: string, name: string }} data.region
   */
  static makeLocation(data) {
    const { location, region } = data;
    return [location?.iso3166Alpha2, region?.iso3166Alpha2].filter(Boolean).join('-');
  }

  async loadTrademarksSuggestions() {
    try {
      const response = await Dataman.DB.getTrademarksSuggestions(this.props.id);
      const result = response.data.map((trademark) => trademark.id);
      return result;
    } catch {
      return [];
    }
  }
}
