import {
  ROLE_COMPANIES,
  ROLE_DIGITAL_HEALTHCARE,
  ROLE_PRODUCTS,
  ROLE_STAFF,
  ROLE_RESEARCH,
  ROLE_VAPING,
  ROLE_PATENT_SPECIALIST,
  ROLE_DEMO_CUSTOMER,
  ROLE_LOGISTIC_MANGER,
  ROLE_ACCOUNTANT,
} from 'App/constants/Roles';
import { CMS_TAB_PRODS_DRAFT, SUB_PRODUCTS_DHW_DEV, SUB_PRODUCTS_VAPING_DEV } from 'App/utils/Collections';

import Welcome from 'Views/Welcome';
import PasswordReset from 'Views/PasswordReset';
import Prices from 'Views/Prices';

const { NODE_ENV } = process.env;
const isProd = NODE_ENV === 'production';

const routes = [
  {
    component: PasswordReset,
    name: 'PasswordReset',
    path: '/reset-password/:token',
    meta: {
      isPublic: true,
    },
  },
  {
    component: Prices,
    name: 'Prices',
    path: '/prices',
    meta: {
      isPublic: true,
    },
  },
  {
    components: {
      public: Welcome,
      default: () => import('Views/Dashboard'),
    },
    name: 'Welcome',
    path: '/',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import('Views/Events'),
    name: 'Calendar',
    path: '/events',
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'Events',
    path: '/events/:id',
    props: {
      category: 'events',
    },
    meta: {
      backPage: 'Calendar',
    },
  },
  {
    component: () => import('Views/Classification'),
    name: 'Classification',
    path: '/classification',
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/News'),
    name: 'News',
    path: '/news',
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/Researches'),
    name: 'Research',
    path: '/research',
    meta: {
      restrictions: [ROLE_STAFF, ROLE_RESEARCH],
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/Search'),
    name: 'Search',
    path: '/search',
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/NewsOverview'),
    name: 'NewsOverview',
    path: '/news/:id',
    meta: {
      backPage: 'News',
    },
  },
  {
    component: () => import('Views/ReportMaker'),
    name: 'ReportMaker',
    path: '/reportMaker',
  },
  {
    component: () => import('Views/Dashboard'),
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    component: () => import('Views/profile/ProfileBookmarks'),
    name: 'ProfileBookmarks',
    path: '/profile/bookmarks',
  },
  {
    component: () => import('Views/profile/ProfileFilters'),
    name: 'ProfileFilters',
    path: '/profile/filters',
  },
  {
    component: () => import('Views/profile/Account'),
    name: 'Profile',
    path: '/profile',
  },
  {
    component: () => import('Views/profile/OrdersAndRequests'),
    name: 'OrdersAndRequests',
    path: '/orders_and_requests/:tab?',
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'BrandInfoFallback',
    path: '/brands/:id(\\d+)',
    props: {
      category: 'brands',
    },
    meta: {
      backPage: '/brands',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'BrandInfo',
    path: '/brands/:subcategory([\\w-]+)/:id(\\d+)',
    props: {
      category: 'brands',
    },
    meta: {
      backPage: 'Brands',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    path: '/brands',
    props: {
      category: 'brands',
    },
    meta: {
      restrictions: [ROLE_STAFF],
      redirectPage: `/brands/${SUB_PRODUCTS_VAPING_DEV}`,
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Brands',
    path: '/brands/:subcategory([\\w-]+)/',
    props: {
      category: 'brands',
    },
    meta: {
      restrictions: {
        param: 'subcategory',
        map: {
          [SUB_PRODUCTS_DHW_DEV]: [ROLE_STAFF, ROLE_DIGITAL_HEALTHCARE, ROLE_DEMO_CUSTOMER],
          [SUB_PRODUCTS_VAPING_DEV]: [ROLE_STAFF, ROLE_VAPING, ROLE_DEMO_CUSTOMER],
        },
      },
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'ProductInfoFallback',
    path: '/products/:id(\\d+)',
    props: {
      category: 'products',
    },
    meta: {
      backPage: '/products',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'ProductInfo',
    path: '/products/:subcategory([\\w-]+)/:id(\\d+)',
    props: {
      category: 'products',
    },
    meta: {
      backPage: 'Products',
    },
  },
  {
    path: '/products',
    redirect: `/products/${SUB_PRODUCTS_VAPING_DEV}`,
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Products',
    path: '/products/:subcategory([\\w-]+)/',
    props: {
      category: 'products',
    },
    meta: {
      restrictions: {
        param: 'subcategory',
        map: {
          [SUB_PRODUCTS_DHW_DEV]: [ROLE_STAFF, [ROLE_PRODUCTS, ROLE_DIGITAL_HEALTHCARE], ROLE_DEMO_CUSTOMER],
          [SUB_PRODUCTS_VAPING_DEV]: [ROLE_STAFF, [ROLE_PRODUCTS, ROLE_VAPING], ROLE_DEMO_CUSTOMER],
        },
      },
      backPage: 'Welcome',
      forcePagination: true,
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Components',
    path: '/components',
    props: {
      category: 'components',
      caption: 'Components (Beta)',
    },
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'ComponentInfo',
    path: '/components/:id',
    props: {
      category: 'components',
      caption: 'Components (Beta)',
    },
    meta: {
      backPage: 'Components',
    },
  },

  {
    component: () => import('Views/ItemInfo'),
    name: 'CompanyInfoFallback',
    path: '/companies/:id(\\d+)',
    props: {
      category: 'companies',
    },
    meta: {
      backPage: '/companies',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'CompanyInfo',
    path: '/companies/:subcategory([\\w-]+)/:id(\\d+)',
    props: {
      category: 'companies',
    },
    meta: {
      backPage: 'Companies',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    path: '/companies',
    props: {
      category: 'companies',
    },
    meta: {
      restrictions: [ROLE_STAFF],
      redirectPage: `/companies/${SUB_PRODUCTS_VAPING_DEV}`,
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Companies',
    path: '/companies/:subcategory([\\w-]+)/',
    props: {
      category: 'companies',
    },
    meta: {
      restrictions: {
        param: 'subcategory',
        map: {
          [SUB_PRODUCTS_DHW_DEV]: [ROLE_STAFF, [ROLE_COMPANIES, ROLE_DIGITAL_HEALTHCARE], ROLE_DEMO_CUSTOMER],
          [SUB_PRODUCTS_VAPING_DEV]: [ROLE_STAFF, [ROLE_COMPANIES, ROLE_VAPING], ROLE_DEMO_CUSTOMER],
        },
      },
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Contacts',
    path: '/contacts',
    props: {
      category: 'contacts',
    },
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'ContactInfo',
    path: '/contacts/:id',
    props: {
      category: 'contacts',
    },
    meta: {
      backPage: 'Contacts',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Trademarks',
    path: '/trademarks',
    props: {
      category: 'trademarks',
    },
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'TrademarkInfo',
    path: '/trademarks/:id',
    props: {
      category: 'trademarks',
    },
    meta: {
      backPage: 'Trademarks',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'Patents',
    path: '/patents',
    props: {
      category: 'patents',
    },
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'PatentInfo',
    path: '/patents/:id',
    props: {
      category: 'patents',
    },
    meta: {
      backPage: 'Patents',
    },
  },
  {
    component: () => import('Views/Agreement'),
    name: 'UserAgreement',
    path: '/user_agreement',
    props: {
      type: 'user',
    },
  },
  {
    component: () => import('Views/Agreement'),
    name: 'SubscriptionAgreement',
    path: '/subscription_agreement',
    props: {
      type: 'subscription',
    },
  },
  {
    component: () => import('Views/ItemsList'),
    name: 'StandingReports',
    path: '/standing_reports',
    props: {
      category: 'standing_reports',
      caption: 'Standing Reports',
    },
    meta: {
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/ItemInfo'),
    name: 'StandingReportInfo',
    path: '/standing_reports/:id',
    props: {
      category: 'standing_reports',
      caption: 'Standing Report',
    },
    meta: {
      backPage: 'StandingReports',
    },
  },
  {
    path: '/standing_reports/:id/section',
    redirect: (route) => ({
      name: 'StandingReportInfo',
      id: route.params.id,
    }),
  },
  {
    component: () => import('Components/standing-report/standing-report-section'),
    name: 'StandingReportSectionCreate',
    path: '/standing_reports/:id/section/create',
    props: true,
    meta: {
      backPage: 'StandingReports',
    },
  },
  {
    component: () => import('Components/standing-report/standing-report-section'),
    name: 'StandingReportSectionUpdate',
    path: '/standing_reports/:id/section/:sectionId/update',
    props: true,
    meta: {
      backPage: 'StandingReports',
    },
  },
  {
    component: () => import('Components/standing-report/standing-report-section-products'),
    name: 'StandingReportSectionProducts',
    path: '/standing_reports/:id/section/:sectionId/products',
    props: true,
    meta: {
      backPage: 'StandingReportInfo',
    },
  },
  {
    component: () => import('Components/standing-report/standing-report-add-products'),
    name: 'StandingReportAddProducts',
    path: '/StandingReportAddProducts',
    meta: {
      backPage: 'StandingReportInfo',
    },
  },
  {
    path: '/standing_reports/:id/section/:sectionId',
    redirect: (route) => ({
      name: 'StandingReportInfo',
      id: route.params.id,
    }),
  },
  {
    component: () => import('@/views/cms/CmsViewIndex'),
    name: 'CMS',
    path: '/cms',
    redirect: () => {
      return {
        name: 'CMSSubList',
        params: { tab: CMS_TAB_PRODS_DRAFT, sub: SUB_PRODUCTS_VAPING_DEV },
      };
    },
    children: [
      {
        path: 'assistant',
        name: 'Assistant',
        components: {
          cms: () => import('@/views/cms/assistant/Assistant'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: '/cms',
        },
      },
      {
        path: ':tab(stats_reports)/user/:id',
        components: {
          cms: () => import('Views/cms/staff-stats/UserStats'),
        },
        name: 'CMSStaffStatsUserStats',
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
      },
      {
        path: ':tab(stats_reports)/:cat?',
        components: {
          cms: () => import('@/views/cms/CmsViewList'),
        },
        name: 'CMSStaffStats',
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
      },
      {
        path: ':tab(stats_reports)/:cat/:id/:action?',
        components: {
          cms: () => import('Views/cms/staff-stats/StatsDetails'),
        },
        name: 'CMSStaffStatsDetails',
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
      },
      {
        path: ':tab(ref_data|components|products_draft|products)/:sub([\\w-]+)',
        name: 'CMSSubList',
        components: {
          cms: () => import('@/views/cms/CmsViewList'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
        strict: true,
      },
      {
        path: ':tab(orders)/:sub(orders)/create',
        name: 'CMSProductOrderCreate',
        components: {
          cms: () => import('@/views/cms/CmsProductOrderForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'CMSOrders',
        },
      },
      {
        path: ':tab(ref_data|components|products_draft|products|orders)/:sub([\\w-]+)/create',
        name: 'CMSSubCreate',
        components: {
          cms: () => import('@/views/cms/CmsViewForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'CMSSubList',
        },
        strict: true,
      },
      {
        path: ':tab(orders)/:sub(orders)/:id(\\d+)',
        name: 'CMSProductOrderUpdate',
        components: {
          cms: () => import('@/views/cms/CmsProductOrderForm'),
        },
        meta: {
          restrictions: [ROLE_LOGISTIC_MANGER, ROLE_ACCOUNTANT],
          backPage: 'CMSOrders',
        },
      },
      {
        path: ':tab(ref_data|components|products_draft|products|orders)/:sub([\\w-]+)/:id(\\d+)',
        name: 'CMSSubUpdate',
        components: {
          cms: () => import('@/views/cms/CmsViewForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'CMSSubList',
        },
        strict: true,
      },
      {
        path: ':tab([\\w-]+)',
        name: 'CMSList',
        components: {
          cms: () => import('@/views/cms/CmsViewList'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
      },
      {
        path: ':tab(patent_research)/create',
        name: 'CMSPatentResearchCreate',
        components: {
          cms: () => import('@/views/cms/CmsPatentResearchForm'),
        },
        meta: {
          restriction: ROLE_PATENT_SPECIALIST,
          backPage: 'CMSList',
        },
      },
      {
        path: ':tab(orders)/:sub(orders|customers|courier_delivery_services|projects|agents)',
        components: {
          cms: () => import('@/views/cms/CmsViewList'),
        },
        name: 'CMSOrders',
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'Welcome',
        },
      },
      {
        path: ':tab([\\w-]+)/create',
        name: 'CMSCreate',
        components: {
          cms: () => import('@/views/cms/CmsViewForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
        },
      },
      {
        path: ':tab(patent_research)/:id(\\d+)',
        name: 'CMSPatentResearchUpdate',
        components: {
          cms: () => import('@/views/cms/CmsPatentResearchForm'),
        },
        meta: {
          restriction: ROLE_PATENT_SPECIALIST,
          backPage: 'CMSList',
        },
      },
      {
        path: ':tab(research)/:id(\\d+)',
        name: 'CMSResearchUpdate',
        components: {
          cms: () => import('@/views/cms/CmsResearchForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'CMSList',
        },
      },
      {
        path: ':tab([\\w-]+)/:id(\\d+)',
        name: 'CMSUpdate',
        components: {
          cms: () => import('@/views/cms/CmsViewForm'),
        },
        meta: {
          restriction: ROLE_STAFF,
          backPage: 'CMSSubList',
        },
      },
    ],
    meta: {
      restriction: ROLE_STAFF,
    },
  },
  {
    component: () => import('Views/StaffStatsPage'),
    name: 'StaffStats',
    path: '/stats/:cat/:id/:action',
    meta: {
      restriction: ROLE_STAFF,
      backPage: 'Welcome',
    },
  },
  {
    component: () => import('Views/Error'),
    name: 'Error',
    path: '/:code',
    meta: {
      isPublic: true,
    },
  },
];

if (isProd) {
  routes.push({
    path: '*',
    redirect: '/404',
  });
}

export default routes;
